<template>
  <v-card>
    <v-card-title>
      <v-icon>mdi-calendar-search</v-icon>
      Aylık Gider Fatura Analiz
    </v-card-title>
    <v-card-text v-if="clusterId">
      <v-row dense>
        <v-col>
          <v-form
            ref="form"
            @submit.prevent="handleFormSubmit"
            class="mb-3"
            :disabled="isLoading"
          >
            <v-row cols="12">
              <v-col sm="2">
                <rs-datepicker
                  label="Başlangıç Tarih"
                  v-model="formData.start_date"
                  :rules="[
                    rules.maxDate(formData.start_date, formData.end_date),
                  ]"
                />
              </v-col>
              <v-col sm="2">
                <rs-datepicker
                  label="Bitiş Tarihi"
                  v-model="formData.end_date"
                  :rules="[
                    rules.minDate(formData.end_date, formData.start_date),
                  ]"
                />
              </v-col>
              <v-col sm="2">
                <rs-select-provider v-model="formData.provider_id" />
              </v-col>
              <v-col sm="2">
                <rs-select-project v-model="formData.project_id" />
              </v-col>
              <v-col sm="2">
                <v-switch
                  v-model="formData.is_active"
                  label="Gider Tiplerini Göster"
                  hide-details="auto"
                ></v-switch>
              </v-col>
              <v-col sm="2">
                <rs-form-buttons
                  :is-loading="isLoading"
                  @submit="handleFormSubmit"
                  hide-cancel
                  hide-save-close
                  saveLabel="Filtrele"
                />
              </v-col>
              <v-col
                sm="12"
                class="d-flex align-items-center justify-content-end"
              >
                <v-btn small color="pink" outlined @click="exportXLSX()">
                  <v-icon>mdi-file-table</v-icon>
                  Dışa Aktar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-simple-table dense id="printTable">
        <template v-slot:default>
          <thead>
            <tr class="bg-secondary bordered">
              <th class="text-left sticky bg-secondary" rowspan="2">
                Gider Türü
              </th>
              <th
                class="text-left sticky bg-secondary"
                rowspan="2"
                v-if="childExpensesEnabled"
              >
                Gider Tipi
              </th>
              <template v-for="year in yearList">
                <th
                  :key="year"
                  :colspan="
                    headerList.filter((h) => h.year === year && !h.totals)
                      .length
                  "
                >
                  {{ year }}
                </th>
                <th rowspan="2" :key="year">{{ year }} Toplam</th>
                <th rowspan="2" :key="year">{{ year }} Ortalama</th>
              </template>
              <th rowspan="2">Genel Toplam</th>
              <!-- <th colspan="7">2021</th>
              <th colspan="9">2022</th> -->
            </tr>
            <tr class="bg-secondary bordered">
              <!-- <th class="text-left">Gider Türü</th> -->
              <th
                class="text-left"
                v-for="cell in headerList.filter((v) => !v.totals)"
                :key="cell.key"
                :rowspan="cell.totals ? 2 : undefined"
              >
                <div>
                  {{ cell.monthName }}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in expenseTypeList"
              :key="row.id + (row.child_id ? '/' + row.child_id : '')"
              class="bordered"
            >
              <td
                class="bg-secondary sticky"
                v-if="row.rowspan !== 0"
                :rowspan="row.rowspan"
              >
                {{ row.expense_type_name }}
              </td>
              <td v-if="childExpensesEnabled" class="bg-secondary sticky">
                <template v-if="row.child_expense_type_name">
                  {{ row.child_expense_type_name }}
                </template>
                <em class="text-muted" v-else>Girilmemiş</em>
              </td>
              <td v-for="cell in row.cells" :key="cell.date" class="text-end">
                <rs-table-cell-number
                  price
                  :value="cell.amount"
                  :class="!cell.amount ? 'text-muted' : ''"
                />
              </td>
              <td class="bg-secondary" v-if="childExpensesEnabled">
                <rs-table-cell-number
                  price
                  :value="
                    totals.expense_type_sums.filter(
                      (v) =>
                        v.expense_type_id === row.id &&
                        v.child_expense_type_id === row.child_id
                    )[0].sum
                  "
                />
              </td>
              <td class="bg-secondary" v-else>
                <rs-table-cell-number
                  price
                  :value="
                    totals.expense_type_sums.filter(
                      (v) => v.expense_type_id === row.id
                    )[0].sum
                  "
                />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="bg-secondary bordered">
              <td
                class="font-weight-bold bg-secondary sticky"
                :colspan="childExpensesEnabled ? 2 : 1"
              >
                Genel Toplam
              </td>
              <td class="text-right" v-for="cell in headerList" :key="cell.key">
                <rs-table-cell-number price :value="cell.sum" />
              </td>
              <td class="bg-secondary bordered">
                <rs-table-cell-number price :value="totals.sum" />
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { filtersToURL, hasExport, hasForm } from "@/view/mixins";
import { utils, writeFile } from "xlsx";

export default {
  name: "ExpensePivotList",
  mixins: [filtersToURL, hasExport, hasForm],
  computed: {
    ...mapGetters(["companyId", "clusterId", "monthList"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    companyId() {
      this.loadList();
    },
  },
  data() {
    return {
      head: null,
      datam: null,
      sumsgeneral: null,
      formData: {},
      isLoading: false,
      childExpensesEnabled: false,
      headerList: [],
      monthDataList: [],
      yearList: [],
      expenseTypeList: [],
      expenseTypeIdList: [],
      expenseTypeSums: [],
      totals: {},
    };
  },
  methods: {
    exportXLSX() {
      try {
        const element = document.getElementById("printTable");
        const ws = utils.table_to_sheet(
          element.getElementsByTagName("TABLE")[0]
        );
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Export");
        return writeFile(wb, "Robosay - Aylık Gider Fatura Analiz.xlsx");
      } catch (error) {
        this.handleError(error);
      }
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        this.list = [];
        return;
      }

      this.setURLParams();
    },
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.clusterId) {
        params.cluster_id = this.clusterId;
      }

      if (this.formData.provider_id) {
        params.provider_id = this.formData.provider_id;
      }

      if (this.formData.project_id) {
        params.project_id = this.formData.project_id;
      }

      params.billed_on = {
        min: this.formData.start_date || undefined,
        max: this.formData.end_date || undefined,
      };

      if (this.formData.is_active) {
        params.child_expenses_enabled = 1;
      }

      params.per_page = 100;

      return params;
    },
    loadList() {
      if (!this.clusterId) {
        return false;
      }

      const params = this.getParams();
      this.isLoading = true;

      this.$api
        .query("expense-analysis/invoice/" + this.clusterId, { params })
        .then((response) => {
          this.headerList = [];
          this.expenseTypeList = [];
          this.monthDataList = [];
          this.totals = {};
          this.expenseTypeSums = [];
          this.yearList = [];

          if (
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            if (params.child_expenses_enabled) {
              this.childExpensesEnabled = true;
              this.loadListChildren(response.data);
            } else {
              this.childExpensesEnabled = false;
              this.loadListMain(response.data);
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    loadListMain(response) {
      this.expenseTypeSums = response.meta.totals.expense_type_sums;
      this.totals = response.meta.totals;
      this.expenseTypeIdList = response.meta.totals.expense_type_monthly_sums
        .map((item) => item.expense_type_id)
        .filter((item, index, array) => {
          return array.indexOf(item, index + 1) === -1;
        });

      this.monthDataList = response.meta.totals.monthly_sums.map(
        (item) => item.year + "-" + item.month
      );

      this.yearList = response.meta.totals.expense_type_monthly_sums
        .map((item) => item.year)
        .filter((item, index, array) => {
          return array.indexOf(item, index + 1) === -1;
        });

      for (let index in response.meta.totals.monthly_sums) {
        index = parseInt(index);
        const row = response.meta.totals.monthly_sums[index];
        row.monthName = this.monthList[row.month - 1];
        row.key = row.year + "-" + row.month;

        this.headerList.push(row);

        if (
          response.meta.totals.monthly_sums[index + 1] === undefined ||
          response.meta.totals.monthly_sums[index + 1].year !==
            response.meta.totals.monthly_sums[index].year
        ) {
          // now we need to push the header for sum and avg
          const yearlySums = response.meta.totals.yearly_sums.filter(
            (item) => item.year === row.year
          )[0];

          const sumRow = {
            year: row.year,
            month: null,
            monthName: row.year + " Toplam",
            sum: yearlySums.sum,
            key: row.year + " Toplam",
            totals: true,
          };
          const avgRow = {
            year: row.year,
            month: null,
            monthName: row.year + " Ortalama",
            sum: yearlySums.avg,
            key: row.year + " Ortalama",
            totals: true,
          };

          this.headerList.push(sumRow);
          this.headerList.push(avgRow);
        }
      }

      for (const index in this.expenseTypeIdList) {
        // now we are working on a single expense type here
        const item = this.expenseTypeIdList[index];
        const list = response.data.filter((v) => v.expense_type_id === item);
        const row = {
          id: list[0].expense_type_id,
          expense_type_name: list[0].expense_type,
          cells: [],
        };

        for (let i in list) {
          i = parseInt(i);
          let totals = null;

          if (list[i + 1] === undefined || list[i + 1].year !== list[i].year) {
            // it's the last month of a year. we need to push the monthly sum and average
            for (const m in response.meta.totals.expense_type_monthly_sums) {
              if (
                response.meta.totals.expense_type_monthly_sums[m].year ===
                  list[i].year &&
                response.meta.totals.expense_type_monthly_sums[m]
                  .expense_type_id === row.id
              ) {
                // we've found it!
                totals = {
                  sum: {
                    totals: true,
                    date: list[i].year + "-sum",
                    amount:
                      response.meta.totals.expense_type_monthly_sums[m].sum,
                  },
                  avg: {
                    totals: true,
                    date: list[i].year + "-avg",
                    amount:
                      response.meta.totals.expense_type_monthly_sums[m].avg,
                  },
                };

                break;
              }
            }
          }

          row.cells.push({
            date: list[i].year + "-" + list[i].month,
            amount: list[i].monthly_amount,
          });

          if (totals !== null) {
            row.cells.push(totals.sum);
            row.cells.push(totals.avg);
          }
        }

        this.expenseTypeList.push(row);
      }
    },
    loadListChildren(response) {
      const headerList = [];
      this.expenseTypeSums = response.meta.totals.expense_type_sums;
      this.totals = response.meta.totals;
      this.expenseTypeIdList = response.meta.totals.unique_list;

      this.monthDataList = response.meta.totals.monthly_sums.map(
        (item) => item.year + "-" + item.month
      );

      this.yearList = response.meta.totals.expense_type_monthly_sums
        .map((item) => item.year)
        .filter((item, index, array) => {
          return array.indexOf(item, index + 1) === -1;
        });

      for (let index in response.meta.totals.monthly_sums) {
        index = parseInt(index);
        const row = response.meta.totals.monthly_sums[index];
        row.monthName = this.monthList[row.month - 1];
        row.key = row.year + "-" + row.month;

        headerList.push(row);

        if (
          response.meta.totals.monthly_sums[index + 1] === undefined ||
          response.meta.totals.monthly_sums[index + 1].year !==
            response.meta.totals.monthly_sums[index].year
        ) {
          // now we need to push the header for sum and avg
          const yearlySums = response.meta.totals.yearly_sums.filter(
            (item) => item.year === row.year
          )[0];

          const sumRow = {
            year: row.year,
            month: null,
            monthName: row.year + " Toplam",
            sum: yearlySums.sum,
            key: row.year + " Toplam",
            totals: true,
          };
          const avgRow = {
            year: row.year,
            month: null,
            monthName: row.year + " Ortalama",
            sum: yearlySums.avg,
            key: row.year + " Ortalama",
            totals: true,
          };

          headerList.push(sumRow);
          headerList.push(avgRow);
        }
      }

      const expenseTypeList = [];

      let lastRow = {};
      for (const index in this.expenseTypeIdList) {
        // now we are working on a single expense type here
        const item = this.expenseTypeIdList[index];
        const list = response.data.filter(
          (v) =>
            v.expense_type_id === item.id &&
            v.child_expense_type_id === item.child_id
        );

        let rowspan = 0;
        if (lastRow.id !== list[0].expense_type_id) {
          rowspan = this.expenseTypeIdList.filter(
            (v) => v.id === item.id
          ).length;
        }

        const row = {
          id: list[0].expense_type_id,
          child_id: list[0].child_expense_type_id,
          expense_type_name: list[0].expense_type,
          child_expense_type_name: list[0].child_expense_type,
          cells: [],
          rowspan: rowspan,
        };

        for (let i in list) {
          i = parseInt(i);
          let totals = null;

          if (list[i + 1] === undefined || list[i + 1].year !== list[i].year) {
            // it's the last month of a year. we need to push the monthly sum and average
            for (const m in response.meta.totals.expense_type_monthly_sums) {
              if (
                response.meta.totals.expense_type_monthly_sums[m].year ===
                  list[i].year &&
                response.meta.totals.expense_type_monthly_sums[m]
                  .expense_type_id === row.id &&
                response.meta.totals.expense_type_monthly_sums[m]
                  .child_expense_type_id === row.child_id
              ) {
                // we've found it!
                totals = {
                  sum: {
                    totals: true,
                    date: list[i].year + "-sum",
                    amount:
                      response.meta.totals.expense_type_monthly_sums[m].sum,
                  },
                  avg: {
                    totals: true,
                    date: list[i].year + "-avg",
                    amount:
                      response.meta.totals.expense_type_monthly_sums[m].avg,
                  },
                };

                break;
              }
            }
          }

          row.cells.push({
            date: list[i].year + "-" + list[i].month,
            amount: list[i].monthly_amount,
          });

          if (totals !== null) {
            row.cells.push(totals.sum);
            row.cells.push(totals.avg);
          }
        }

        expenseTypeList.push(row);

        lastRow = Object.assign({}, row);
      }

      this.expenseTypeList = expenseTypeList;
      this.headerList = headerList;
    },
  },
  mounted() {
    this.loadURLParams();
  },
};
</script>

<style>
tr.bordered th,
tr.bordered td {
  border: 1px solid #0000001f;
}

tr > td.sticky,
tr > th.sticky {
  border: 1px solid #0000001f !important;
  left: 0;
  position: sticky;
}

tr > td.sticky.sticky-top,
tr > th.sticky.sticky-top {
  top: 0;
}
</style>
